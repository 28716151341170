<template>
  <div class="contact">
    <head-nav></head-nav>
    <div class="contact-banner">
      <img
        src="@/assets/image/contact/contact.png"
        alt=""
      />
    </div>
    <div class="contact-box">
      <div class="wrap">
        <div class="contact-top">
          <h3 class="contact-top-title">
            <img
              :src="$t('contact.title-1')"
              alt=""
            />
          </h3>
          <div class="contact-top-img">
            <div class="contact-top-imgs">
              <div class="contact-top-info">
                <div class="contact-top-info-content">
                  <img
                    src="@/assets/image/contact/contact1-2.png"
                    alt=""
                  />

                  <div class="contact-top-info-details">
                    <div class="contact-title">
                      {{$t('phone')}}
                    </div>

                    <div class="contact-content">
                      {{ contact1[0].phone }}
                    </div>

                  </div>
                </div>

                <img
                  class="contact-line"
                  src="@/assets/image/contact/contact-line.png"
                  alt=""
                >

              </div>
            </div>

            <div class="contact-top-imgs">
              <div class="contact-top-info">
                <div class="contact-top-info-content">
                  <img
                    src="@/assets/image/contact/contact1-3-1.png"
                    alt=""
                  />

                  <div class="contact-top-info-details">
                    <div class="contact-title">
                      {{$t('fax')}}
                    </div>

                    <div class="contact-content">
                      {{ contact1[0].fax }}
                    </div>

                  </div>
                </div>

                <img
                  class="contact-line"
                  src="@/assets/image/contact/contact-line.png"
                  alt=""
                >

              </div>
            </div>

            <div class="contact-top-imgs">
              <div class="contact-top-info">
                <img
                  src="@/assets/image/contact/contact1-3.png"
                  alt=""
                />
                <div class="contact-top-info-details">

                  <div class="contact-title">{{$t('email')}}</div>
                  <div class="contact-content">Joyceyy88@163.com</div>
                </div>
              </div>
            </div>

            <div class="contact-top-content">
              <div>
                {{contact1[0].content}}

              </div>
              <div class="map">
                <img src="@/assets/image/contact/map.png" alt="">
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
    <div class="messages">
      <div class="wrap">
        <div class="message">
          <h3 class="message-title">{{$t('contact.title-2')}}</h3>

          <form action="">
            <div class="message-info">
              <label for="user">
                <span>{{$t('contact.name.label')}}</span>
                <input
                  type="text"
                  name="user"
                  :placeholder="$t('contact.name.placeholder')"
                  v-model="name"
                /> </label>
            </div>
            <div class="message-info">
              <label for="email">
                <span>{{$t('contact.email.label')}}</span>
                <input
                  type="text"
                  name="email"
                  :placeholder="$t('contact.email.placeholder')"
                  v-model="email"
                /> </label>
            </div>
            <div class="message-info">
              <label for="phone">
                <span>{{$t('contact.telephone.label')}}</span>
                <input
                  type="text"
                  name="phone"
                  :placeholder="$t('contact.telephone.placeholder')"
                  v-model="phone"
                /> </label>
            </div>
            <div class="message-info">
              <label for="company">
                <span>{{$t('contact.company.label')}}</span>
                <input
                  type="text"
                  name="company"
                  :placeholder="$t('contact.company.placeholder')"
                  v-model="company"
                /> </label>
            </div>
            <div class="message-info">
              <label for=""> <span>{{$t('contact.comment.label')}}</span>
                <textarea
                  :placeholder="$t('contact.comment.placeholder')"
                  v-model="content"
                ></textarea> </label>
            </div>
          </form>
          <div class="message-btn">
            <button @click="addFeedback">{{$t('contact.submit')}}</button>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { required, email, numeric } from 'vuelidate/lib/validators';
import HeadNav from './head-nav';

import Footers from './footers';

export default {
  title: 'Change direction',
  components: {
    HeadNav,
    Footers,
  },

  apollo: {
    // 简单的查询，将更新 'hello' 这个 vue 属性
    contact1: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              contact1: allIntroduceMyselves {
                phone
                fax
                content
              }
            }
          `;
        }
        return gql`
          query {
            contact1: allEnIntroduceMyselves {
              phone
              fax
              content
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      contact1: [
        {
          phone: '',
          fax: '',
        },
      ],
      name: '',
      email: '',
      phone: '',
      company: '',
      content: '',
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
      numeric,
    },
    company: {
      required,
    },
    content: {
      required,
    },
  },
  methods: {
    async addFeedback() {
      if (this.$i18n.locale === 'cn') {
        try {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$notify({
              type: 'error',
              title: '请完善信息',
            });
            return;
          }
          await this.$apollo.mutate({
            // 查询语句
            mutation: gql`
              mutation(
                $name: String!
                $email: String!
                $phone: String!
                $company: String!
                $content: String!
              ) {
                createFeedback(
                  data: {
                    name: $name
                    email: $email
                    phone: $phone
                    company: $company
                    content: $content
                  }
                ) {
                  id
                }
              }
            `,
            // 参数
            variables: {
              name: this.name,
              email: this.email,
              phone: this.phone,
              company: this.company,
              content: this.content,
            },
          });
          this.$notify({
            type: 'success',
            title: '提交成功',
          });
          Object.assign(this, {
            name: '',
            email: '',
            phone: '',
            company: '',
            content: '',
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: '提交失败',
          });
        }
      } else if (this.$i18n.locale === 'en') {
        try {
          this.$v.$touch();
          if (this.$v.$invalid) {
            this.$notify({
              type: 'error',
              title: 'Please complete the information',
            });
            return;
          }
          await this.$apollo.mutate({
            // 查询语句
            mutation: gql`
              mutation(
                $name: String!
                $email: String!
                $phone: String!
                $company: String!
                $content: String!
              ) {
                createFeedback(
                  data: {
                    name: $name
                    email: $email
                    phone: $phone
                    company: $company
                    content: $content
                  }
                ) {
                  id
                }
              }
            `,
            // 参数
            variables: {
              name: this.name,
              email: this.email,
              phone: this.phone,
              company: this.company,
              content: this.content,
            },
          });
          this.$notify({
            type: 'success',
            title: 'success',
          });
          Object.assign(this, {
            name: '',
            email: '',
            phone: '',
            company: '',
            content: '',
          });
        } catch (error) {
          this.$notify({
            type: 'error',
            title: 'error',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  .contact-banner {
    width: 100%;
    height: 504px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contact-box {
    background: #fff;

    .contact-top {
      padding: 90px 0 80px 0;

      .contact-top-title {
        text-align: center;
        margin-bottom: 30px;
      }

      .contact-top-img {
        width: 1000px;
        margin: 30px auto;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;

        .contact-top-imgs {
          width: 320px;
          margin-top: 20px;
          position: relative;

          &:nth-child(2) {
            .contact-top-info {
              .contact-top-info-content {
                position: relative;
                left: 30px;
              }
            }
          }

          &:nth-child(3) {
            .contact-top-info {
              justify-content: flex-end;
            }
          }

          .contact-top-info {
            font-size: 24px;
            font-weight: 500;
            margin-left: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .contact-top-info-content {
              display: flex;
            }

            &-details {
              margin-left: 30px;

              .contact-title {
                font-size: 18px;
                color: #555;
                margin: 2px 0;
              }
              .contact-content {
                font-size: 24px;
              }
            }

            img {
              top: 0px;
              left: 160px;
              width: 50px;
              height: 50px;
            }

            .contact-line {
              margin-left: 30px;
              width: 1px;
              height: auto;
            }
          }
        }
      }

      .contact-top-content {
        padding: 20px 0;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        width: 1000px;
        margin: 40px auto;
        line-height: 24px;
        font-size: 14px;
        color: #555;

        .map{
          margin-top: 40px;
        }
      }
    }
  }

  .messages {
    width: 100%;

    .wrap {
      padding: 0 0 90px 0;

      .message {
        margin: 0 auto;
        width: 600px;
        height: 550px;
        border: 1px solid #eee;

        .message-title {
          margin: 30px 0;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #555;
        }

        form {
          .message-info {
            text-align: center;

            label {
              font-size: 16px;
              color: #555;
              position: relative;

              input {
                margin-left: 20px;
                width: 350px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #eee;
                outline: none;
                margin-bottom: 20px;
                padding: 0 10px;
                background: #f8f8f8;
                font-size: 16px;
                font-family: Arial;
                &::-webkit-input-placeholder {
                  font-family: '微软雅黑';
                }
              }

              textarea {
                margin-left: 20px;
                width: 350px !important;
                height: 120px;
                border-radius: 6px;
                border: 1px solid #eee;
                outline: none;
                margin-bottom: 20px;
                padding: 10px 10px;
                background: #f8f8f8;
                font-size: 16px;
                resize: none;
                font-family: Arial;
                &::-webkit-input-placeholder {
                  font-family: '微软雅黑';
                }
              }
            }

            span {
              position: relative;
              top: 0px;
              left: 0;
              width: 80px;
              display: inline-block;
            }
          }

          .message-info:last-child {
            label {
              span {
                position: relative;
                top: -120px;
                left: 0;
              }
            }
          }
        }

        .message-btn {
          text-align: center;

          button {
            width: 200px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border: none;
            outline: none;
            background: #ffbf00;
            border-radius: 20px;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .contact .contact-banner,
  .contact .contact-banner img {
    width: 1200px;
  }
}
</style>
